import { template as template_bea556afcbe6483a9b39f1cbd01e27af } from "@ember/template-compiler";
const FKLabel = template_bea556afcbe6483a9b39f1cbd01e27af(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
