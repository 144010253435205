import { template as template_5b8af8d119dc4b1fbc60c356a334a9a4 } from "@ember/template-compiler";
const FKText = template_5b8af8d119dc4b1fbc60c356a334a9a4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
